<template>
  <div></div>
</template>
<script>
export default {
  methods: {
    async redirect () {
      const accountNumber = this.$route.query.accountNumber
      const division = this.$route.query.division

      const r = await this.$http().get('/core/account-by-number', {
        params: {
          accountNumber,
          division
        }
      })

      const id = r.data.id

      this.$router.replace(`/accounts/${id}`).catch(() => { })
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler () {
        this.redirect()
      }
    }
  }
}
</script>
